import React from "react";
import { Helmet } from "react-helmet";

function Title({searchIndex, username, tagline }) {
  let searchIndexValue;
  if (searchIndex === undefined) {
    searchIndexValue = true; // Default value if undefined
  } else {
    searchIndexValue = searchIndex; // Set to true or false based on actual value
  }
  return (
    <Helmet>
      <title>{`${username}  | OpenSign™ profile ${
        tagline && `- ${tagline}`
      }`}</title>
      <meta name="description" content={`View ${username}’s OpenSign™ profile to sign contracts, NDAs, agreements, and other legal documents. Easily collect free digital signatures on your documents with OpenSign™—secure, fast, and free!`} />
      {searchIndexValue && <meta name="robots" content="noindex" />}
      <link
        rel="icon"
        type="image/png"
        href={localStorage.getItem("fev_Icon")}
        sizes="40x40"
      />
    </Helmet>
  );
}

export default Title;
